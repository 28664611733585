import { useRouter } from 'next/router';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSurvey } from '../containers/SurveyContainer';
import { ModalBody, ModalFooter } from '../pagestyles/components/ModalUnfinishedQuiz';
import Button from './common/button/Button';

type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModalUnfinishedQuiz: React.FC<Props> = ({ showModal, setShowModal }: Props) => {
  const router = useRouter();
  const { setLocalCurrentQuestion, setLocalQuestions, setResponses } = useSurvey();
  const resetQuiz = () => {
    setLocalCurrentQuestion.reset();
    setLocalQuestions.reset();
    setResponses.reset();
    // router.push('/survey');
    window.location.href = '/survey';
  };
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
      <ModalBody className='unfinished-modal'>
        <span className='close-btn' onClick={() => setShowModal(false)} aria-hidden>
          &times;
        </span>
        <div className='modal-body text-center custom-title-quiz'>Do you wish to start a new quiz? Your previous answers won&apos;t be saved.</div>
        <ModalFooter>
          <Button type='tertiary' onClick={() => resetQuiz()}>
            Start again
          </Button>
          <Button
            type='alternative'
            onClick={() => {
              setShowModal(false);
              router.push('/survey');
            }}
          >
            Resume
          </Button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};
export default ModalUnfinishedQuiz;
