/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import {
  black, green, nHaas55, red,
} from '../../../styles/theme';

export const ModalBody = styled(Modal.Body)`
  background-color: white;
  border: none;

	.close-btn {
		cursor: pointer;
		font-size: 24px;
		font-weight: 600;
		position: absolute;
		top: 0;
		right: calc(0% + 10px);
	}

  .custom-title-quiz {
    color: ${black};
    font-size: 18px;
  }
`;
export const ModalFooter = styled(Modal.Footer)`
  justify-content: center;
  padding: 0.75rem 0;
  .button-close-quiz, .button-save-quiz, .button-start-again, .button-recommendation {
    cursor: pointer;
    min-width: 200px;
    border-radius: 0;
    font-family: ${nHaas55};
    font-size: 18px;
    border-width: 1.5px;
    padding: 11px 15px;
    color: ${black};
    border-width: 1.5px;
    border-color: ${black};

    @media only screen and (max-width: 500px) {
      min-width: 150px;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .button-start-again, .button-recommendation {
    min-width: 280px;
  }
  .button-close-quiz, .button-start-again {
    background-color: ${green};
  }
  .button-save-quiz, .button-recommendation {
    background-color: ${red};
  }
`;
